import React from "react";
import { Flex, Space, Typography } from "antd";
import { isMobile } from "react-device-detect";

const { Text } = Typography;

interface SelectSectionProps {
  icon: React.FC;
  text: string;
  textColor: string;
  isDelete: boolean;
}

const IconTextMobile: React.FC<SelectSectionProps> = ({
  icon,
  text,
  textColor,
  isDelete = false,
}) => (
  <>
    <Flex
      align="center"
      vertical
      style={{ marginLeft: "25px", textAlign: "center" }}
    >
      <div>
        <Text
          strong={true}
          delete={isDelete}
          style={{ color: textColor, fontSize: "20px" }}
        >
          {text}
        </Text>
      </div>
    </Flex>
  </>
);
export default IconTextMobile;
