import React from "react";
import { Flex, Space, Typography } from "antd";
import { isMobile } from "react-device-detect";

const { Text } = Typography;

interface SelectSectionProps {
  icon: React.FC;
  text: string;
  textColor: string;
  isDelete: boolean;
}

const IconText: React.FC<SelectSectionProps> = ({
  icon,
  text,
  textColor,
  isDelete = false,
}) => (
  <>
    <Space style={{ marginLeft: "25px" }}>
      {React.createElement(icon)}
      <Text
        strong={false}
        delete={isDelete}
        style={{ color: textColor, fontSize: "20px" }}
      >
        {text}
      </Text>
    </Space>
  </>
);
export default IconText;
