export interface SearchRequest {
  randomIdentity: number;
  tags: string[];
  page: number;
  sort:Sorting;
  sortAscending:boolean
}

export interface SearchResponse {
  id?: string ;
  incrementalId?: string ;
  title?: string;
  subTitle?: string;
  price: number;
  oldPrice: number;
  discountPercentage: number;
  image?: string;
  imageThumb?: string;
  platform?: string;
  platformEn?: string;
  url?: string;
  category?: string;
  categoryEn?: string;
  // tags?: string[];
}

export enum Sorting
{
    Nothing=0,
    MostValuable,
    Cheapest,
    MostDiscountPercentage,
    MostDiscountValue,
}


export interface TagsDto
{
     tags:string[],
     type:TagType,
}

export enum TagType
{
    All,
    Category,
    Platform,
    SearchKeyWord
}

