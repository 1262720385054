import React from "react";
import { Select, SelectProps, Tag } from "antd";
import { isMobile } from "react-device-detect";
import { DefaultOptionType } from "antd/es/select";

interface SelectSectionProps {
  loading: boolean;
  tags: DefaultOptionType[];
  tagRender: SelectProps["tagRender"];
  onClear: () => void;
  onChange: (value: string) => void;
}

const QuickSearch: React.FC<SelectSectionProps> = ({
  loading,
  tags,
  tagRender,
  onClear,
  onChange,
}) => (
  <Select
    style={{ minWidth: isMobile ? "80%" : "26%", margin: "3px" }}
    placeholder="جستجو خیلی سریع"
    tagRender={tagRender}
    loading={loading}
    size="large"
    // onSelect={(value) => {
    //   debugger;
    //   // searchingTags.push(value);
    //   setSearchingTags([value]);
    //   setTimeout(() => {
    //     fetchItems(true);
    //   }, 1000);
    // }}
    allowClear
    // onClear={() => {
    //   setSearchingTags([]);
    //   setTimeout(() => {
    //     fetchItems(true);
    //   }, 1000);
    // }}
    onClear={onClear}
    onChange={onChange}
    options={tags}
    virtual={false}
  />
);

export default QuickSearch;