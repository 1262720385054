import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Affix,
  Segmented,
  Flex,
  SelectProps,
  Tag,
  Modal,
} from "antd";
import { SearchItems, fetchTags } from "./api-service";
import {
  SearchRequest,
  SearchResponse,
  Sorting,
  TagType,
  TagsDto,
} from "./models/api";
import { isMobile } from "react-device-detect";
import { DefaultOptionType } from "antd/es/select";
import { Typography } from "antd";
import SegmentedSection from "./components/sorting";
import ProductsList from "./components/products-list";
import QuickSearch from "./components/quick-search";
import Title from "antd/es/typography/Title";

const { Text, Link } = Typography;

type TagRender = SelectProps["tagRender"];
const tagRender: TagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={"cyan"}
      onMouseDown={onPreventMouseDown}
      closable={false}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
};

const App = () => {
  const [items, setItems] = useState<SearchResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState<TagsDto[]>([]);
  const [searchingTags, setSearchingTags] = useState<string[]>([]);
  const [request, setRequst] = useState<SearchRequest>({} as SearchRequest);
  const [isBuyingModalOpen, setIsBuyingModalOpen] = useState(false);
  const [isGuideModalOpen, setisGuideModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<SearchResponse>();

  let loadingRequest = false;

  useEffect(() => {
    request.page = 1;
    fetchItems();
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [items]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchItems(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchingTags]);

  const fetchItems = async (reload: boolean = false) => {
    if(!items || items.length === 0)
      setisGuideModalOpen(true);
    if (loadingRequest) return;
    loadingRequest = true;
    setLoading(true);
    if (tags.length == 0) {
      fetchTags().then((tags) => {
        setTags(tags);
      });
    }
    const updatedRequest = { ...request };
    updatedRequest.tags = searchingTags;
    if (reload) updatedRequest.page = 1;

    try {
      const data = await SearchItems(updatedRequest);
      if (data) {
        setItems(reload ? data : [...items, ...data]);
      }
    } finally {
      setLoading(false);
      loadingRequest = false;
    }

  };

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollBottom = documentHeight - windowHeight - scrollTop;

    if (scrollBottom < 1 && !loadingRequest) {
      // Reached the bottom of the list, load more data
      request.page += 1;
      setRequst(request);
      fetchItems();
    }
  };
  // Scroll to top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const SelectTags = (): DefaultOptionType[] => {
    let typeToName = (key: TagType): string => {
      switch (key) {
        case TagType.Category:
          return " دسته بندی ها";
        case TagType.Platform:
          return " پلتفرم ها";
        case TagType.SearchKeyWord:
          return "کلمات کلیدی";
      }
      return "";
    };
    let t =
      tags &&
      tags.map(
        (value) =>
          ({
            label: typeToName(value.type),
            options: value.tags.map(
              (i) => ({ label: i, value: i } as DefaultOptionType)
            ),
          } as DefaultOptionType)
      );

    return t;
  };
  const Redirect = (SearchResponse: SearchResponse) => {
    setSelectedItem(SearchResponse);
    setIsBuyingModalOpen(true);
    setTimeout(() => {
      setIsBuyingModalOpen(false);
      window.open(SearchResponse.url, "_blank");
    }, 2500);
    // Modal.confirm({
    //   title: "میخوام بخرم",
    //   content: `برای خرید باید بری روی ${SearchResponse.platform} خرید رو نهایی کنی`,
    //   okText: "باشه برو",
    //   cancelText: "نمیخوام!",
    //   onOk: () => {
    //     window.open(SearchResponse.url, "_blank");
    //   },
    // });
  };

  return (
    <>
      <Modal
        title="خرید"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        open={isBuyingModalOpen}
      >
        <p>
          {" "}
          برای خرید میفرستمت به سایت {selectedItem?.platform} تا اونجا خریدت رو
          نهایی کنی
        </p>
      </Modal>

      <Modal
        title="مُفتی چیست؟"
        cancelButtonProps={{ style: { display: "none" } }}
        open={isGuideModalOpen}
        okText='عالی'
        onOk={() => {
          setisGuideModalOpen(false);
        }}
      >
        <Title level={5}>ما تو اینجا بهتون تخفیف های شگفت انگیز و حراج های روزانه سایت های مختلف رو نشون میدیم</Title>
        <Title level={5}>بهترین تخفیف ها روزانه جمع آوری میشن و دیگه لازم نیست کلی سایت رو روزانه چک کنید</Title>
        <Title level={5}>کافیه روزانه یه نگاهی به لیست این سایت کنید</Title>
      </Modal>
      <Affix offsetBottom={isMobile ? 0 : 1} offsetTop={isMobile ? 1 : 0}>
        <Flex
          gap="center"
          align="center"
          vertical
          style={{ backgroundColor: "rgb(225 225 225)", padding: "3px 30px" }}
        >
          <div style={{ margin: "3px", padding: "0px 20px", display: "flex" }}>
            <SegmentedSection
              onChange={(value) => {
                request.page = 1;
                let sorting = value as Sorting;
                if (sorting == Sorting.Cheapest) request.sortAscending = true;
                else request.sortAscending = false;
                request.sort = value;
                setRequst(request);
                fetchItems(true);
                scrollToTop();
              }}
            />
          </div>
          <QuickSearch
            loading={loading}
            tags={SelectTags()}
            tagRender={tagRender}
            onClear={() => {
              scrollToTop();
              setSearchingTags([]);
            }}
            onChange={(value) => {
              scrollToTop();
              setSearchingTags([value]);
            }}
          ></QuickSearch>
        </Flex>
      </Affix>
      <Row style={{ margin: "2%" }} gutter={[16, 16]} justify="center">
        <ProductsList items={items} loading={loading} onRedirect={Redirect} />
      </Row>
    </>
  );
};

export default App;
