import React from "react";
import { List, Image, Button, Flex, Badge } from "antd";
import { isMobile } from "react-device-detect";
import Icon, { DownCircleOutlined, UpCircleOutlined, FallOutlined } from "@ant-design/icons";
import { SearchResponse } from "../models/api";
import IconText from "./icon-text";
import IconTextMobile from "./icon-text-mobile";

interface ListSectionProps {
  items: SearchResponse[];
  loading: boolean;
  onRedirect: (item: SearchResponse) => void;
}

const ProductsList: React.FC<ListSectionProps> = ({ items, loading, onRedirect }) => (
    <List
          style={{ textAlign: isMobile ? "center" : "initial" }}
          itemLayout="vertical"
          size="large"
          // pagination={{
          //   onChange: (page) => {
          //     console.log(page);
          //   },
          //   hideOnSinglePage:true,
          // }},
          loading={loading}
          dataSource={items}
          footer={<div>{loading && <span>وایسا ...</span>}</div>}
          renderItem={(item) => (
            <>
              <List.Item
                key={item.id}
                actions={[
                  (isMobile && (
                    <>
                      <Flex vertical>
                        <IconTextMobile
                          textColor="green"
                          icon={DownCircleOutlined}
                          text={`${item.price.toLocaleString()} تومان `}
                          key="list-vertical-star-o"
                          isDelete={false}
                        />
                        <IconTextMobile
                          textColor="#fbb3b3"
                          icon={UpCircleOutlined}
                          text={`${item.oldPrice.toLocaleString()} تومان `}
                          key="list-vertical-like"
                          isDelete={true}
                        />
                        <IconTextMobile
                          textColor="rgb(87 113 155)"
                          icon={UpCircleOutlined}
                          text={`${(
                            item.oldPrice - item.price
                          ).toLocaleString()} سود شما از خرید `}
                          key="list-vertical-like"
                          isDelete={false}
                        />
                      </Flex>
                    </>
                  )) ||
                    (!isMobile && (
                      <>
                        <IconText
                          textColor="green"
                          icon={UpCircleOutlined}
                          text={`${item.price.toLocaleString()} تومان `}
                          key="list-vertical-star-o"
                          isDelete={false}
                        />
                        <IconText
                          textColor="#fbb3b3"
                          icon={DownCircleOutlined}
                          text={`${item.oldPrice.toLocaleString()} تومان `}
                          key="list-vertical-like"
                          isDelete={false}
                        />
                        <IconText
                          textColor="rgb(87 113 155)"
                          icon={DownCircleOutlined}
                          text={`${(
                            item.oldPrice - item.price
                          ).toLocaleString()} تخفیف `}
                          key="list-vertical-like"
                          isDelete={false}
                        />
                        <IconText
                          textColor="blue"
                          icon={FallOutlined}
                          text={`%${item.discountPercentage.toLocaleString()} درصد تخفیف`}
                          key="list-vertical-message"
                          isDelete={false}
                        />
                      </>
                    )),
                ]}
                extra={
                  <Image.PreviewGroup items={[item.image ?? ""]}>
                    <Badge.Ribbon  text={`%${item.discountPercentage}`} 
                    style={{bottom:'8px',top:'initial',visibility:isMobile?'inherit':'hidden',fontWeight:'bolder'}} placement="start" color="#ef6c00" >
                     {/* <Badge.Ribbon text={item.platform} placement="end" color="#cddc39c7"> */}
                     <Icon
                        component={() => (
                            <Image
                            // width={272}
                            // height={200}
                            style={{
                              width:isMobile?'initial':'272px',
                              height:isMobile?'200px':'initial',
                              padding: "3px",
                              backgroundColor: "#ffffffcf",
                              borderRadius: "4%",
                            }}
                            alt="logo"
                            src={item.imageThumb ?? ""}
                          />

                        )}
                      />
                     {/* </Badge.Ribbon> */}
                    </Badge.Ribbon>
                  </Image.PreviewGroup>
                }
              >
                <List.Item.Meta
                  // avatar={<Avatar src={item.avatar} />}
                  title={<a>{item.title}</a>}
                  description={item.category}
                />
                {item.subTitle}
                <br />
                <br />
                <Button
                  style={{ width: isMobile ? "70%" : "initial" }}
                  type="primary"
                  danger
                  size="large"
                  onClick={() => onRedirect(item)}
                >
                  خرید
                </Button>
              </List.Item>
              <hr className="Orange-Line" />
            </>
          )}
        />
);

export default ProductsList;