import axios from "axios";
import { SearchRequest, SearchResponse, Sorting, TagsDto } from "./models/api";

const API_URL =(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
  ?"https://localhost:44373/api/":"/api/"; // Replace this with your actual API URL
const axiosConfig={withCredentials: false};
const fetchItems = async (): Promise<any[]> => {
  try {
    const response = await axios.get<any[]>(API_URL,axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    return [];
  }
};

const fetchTags = async (): Promise<TagsDto[]> => {
  try {
    const response = await axios.get<TagsDto[]>(API_URL+'app/discounted-products/popular-tags',axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    return [];
  }
};

const SearchItems = async (newItem: SearchRequest): Promise<SearchResponse[]|null> => {
  try {
    if(!newItem.tags)
      newItem.tags=[];
    if(!newItem.sort)
      newItem.sort=Sorting.Nothing;
    if(!newItem.page)
      newItem.page=1;
    if(!newItem.sortAscending)
      newItem.sortAscending=false;
    const response = await axios.post<SearchResponse[]>(API_URL+'app/discounted-products/items-with-categories', newItem,axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Error creating item:", error);
    return null;
  }
};

export { fetchItems, SearchItems,fetchTags };
