import React from "react";
import { Avatar, Segmented } from "antd";
import { SegmentedValue } from "antd/es/segmented";
import { Sorting } from "../models/api";

interface SegmentedSectionProps {
  onChange: (value: Sorting) => void;
}

const SegmentedSection: React.FC<SegmentedSectionProps> = ({ onChange }) => (
  <Segmented
    options={[
      {
        label: (
          <div style={{ padding: 4 }}>
            <Avatar src="https://img.icons8.com/?size=100&id=lsPIBnd2V8GE&format=png" />
            <div className="Vazir">درهم</div>
          </div>
        ),
        value: Sorting.Nothing,
      },
      {
        label: (
          <div style={{ padding: 4 }}>
            <Avatar src="https://img.icons8.com/?size=100&id=0zy7lQ66ltnN&format=png" />
            <div className="Vazir">مُفت ترین</div>
          </div>
        ),
        value: Sorting.MostValuable,
      },
      {
        label: (
          <div style={{ padding: 4 }}>
            <Avatar src="https://img.icons8.com/?size=100&id=rryLKtnoNqw7&format=png" />
            <div className="Vazir">ارزان ترین</div>
          </div>
        ),
        value: Sorting.Cheapest,
      },
      {
        label: (
          <div style={{ padding: 4 }}>
            <Avatar src="https://img.icons8.com/?size=100&id=NAAWOySO7iWm&format=png" />
            <div className="Vazir">بیشترین تخفیف</div>
          </div>
        ),
        value: Sorting.MostDiscountPercentage,
      },
    ]}
    onChange={onChange}
  />
);

export default SegmentedSection;
