import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css"; // Include Tailwind CSS styles
import { Affix, Button, ConfigProvider, Layout } from "antd";
import { Header } from "antd/es/layout/layout";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ConfigProvider direction="rtl">
    <Layout>
      <Header
        className="site-layout-background"
        style={{
          padding: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
          <img src="/logo.jpeg" width={100}  />
      </Header>

      <App />
    </Layout>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
